import DomComponent from '../lib/domComponent';
import { each, mq } from '../lib/util';

export default class ValidationComponent extends DomComponent {
    static selector = '[data-js-validate]';

    requiredInputSelector = '[data-required]'
    errorMessageMap = {};

    constructor(el) {
        super(el);

        this.el.addEventListener('submit', (e) => {
            const valid = this.validate();

            if(!valid) {
                e.preventDefault();
            };
        })
    }

    validate() {
        let valid = true;
        each(this.el.querySelectorAll(this.requiredInputSelector), (input) => {
            if(!input.value) {
                valid = false;
                this.createErrorMessage(input);
            }else{
                this.removeErrorMessage(input);
            }
        });

        return valid;        
    }

    createErrorMessage(input) {
        if(!input.classList.contains('validation-error')) {
            const name = input.dataset.required ? input.dataset.required : input.getAttribute('name');
            input.classList.add('validation-error')

            let div = document.createElement('div');
            div.classList.add('validation-error-message');
            div.innerText = `Please enter a valid ${name}`;

            input.parentElement.appendChild(div);

            this.errorMessageMap[name] = div;
        }
    }

    removeErrorMessage(input) {
        if(input.classList.contains('validation-error')) {
            const name = input.dataset.required ? input.dataset.required : input.getAttribute('name');
            input.classList.remove('validation-error');

            this.errorMessageMap[name].remove();
            delete this.errorMessageMap[name];
        }
    }
}
