import WidgetBase from '../widget.base';
import {each, isDev, getUrlParams, querify} from '../../lib/util';
import SendRequest from '../../lib/ajax';
import calloutTemplate from './blog-callout.template.pug';

export default class BlogGalleryWidget extends WidgetBase {
    static selector = '[data-js-blog]';
    api = 'umbraco/rhythm/blogapi/filter';
    data;
    params = {};
    activeFilter;
    activeClass = "active";
    allowedParams = ['term', 'skip', 'take', 'tag'];
    defaultParams = {
        take: 8,
        skip: 0
    };

    constructor(el){
        super(el); 
        if(!el){
            return false;
        }

        this.grid = this.el.querySelector('[data-grid]');
        this.filters = this.el.querySelectorAll('[data-filter]');
        this.loadMore = this.el.querySelector('[data-more]');

        this.loadMore.addEventListener('click', this.loadMoreHandler.bind(this));
        each(this.filters, (filter) => {
            filter.addEventListener('click', this.filterHandler.bind(this, filter));
        })

        this.getInitialParams();
        this.getContent();

        setTimeout(() => {
            this.loaded();
        })
    }

    getContent(clear) {
        const queryString = querify(this.params);
        let url = this.api + '?' + queryString;
        if(isDev()) {
            url = 'http://dev.anchorum.com/' + url;
        }

        return new SendRequest(url)
            .then((res) => {
                if(res && res.hasOwnProperty('text')) {
                    const data = JSON.parse(res.text);
                    this.data = data;
                    this.render(data.items, clear);
                }
            })
            .catch((err) => {
                if(err) {
                    console.error(err);
                }
            })
    }

    filterHandler(filter) {
        const tag = filter.dataset.filter;
        if(this.params.tag && this.params.tag === tag) {
            return false;
        }

        // set active clases
        if(this.activeFilter) {
            this.activeFilter.classList.remove(this.activeClass)
        }
        this.activeFilter = filter;
        this.activeFilter.classList.add(this.activeClass);

        this.getInitialParams();
        this.params.tag = tag;
        this.getContent(true);
    }

    clear() {
        this.grid.innerHTML = '';
    }

    render(items, clear) {
        let html = clear ? '' : this.grid.innerHTML;
        items.forEach((item) => {
            html += calloutTemplate(item);
        });

        this.grid.innerHTML = html;

        if(this.grid.children.length >= this.data.totalCount) {
            this.loadMore.classList.add('hidden');
        }else{
            this.loadMore.classList.remove('hidden');
        }
    }

    loadMoreHandler() {
        this.params.skip = this.params.skip + this.params.take;
        this.getContent();
    }

    getInitialParams() {
        const supplied = getUrlParams();
        let initial = {};

        this.allowedParams.forEach((param) => {
            if(supplied.hasOwnProperty(param)) {
                initial[param] = supplied[param];
            }
        });

        this.params = Object.assign(this.defaultParams, initial);
    }
}