import WidgetBase from '../widget.base';
import NavService from '../../services/nav';
import Carousel from '../../services/carousel';
import { mq, getVerticalOffset } from '../../lib/util';

export default class HeroCarouselWidget extends WidgetBase {
    static selector = '[data-js-hero-carousel]';
    activeClass = 'active';
    visibleClass = 'visible';
    buttonClass = 'hero__nav__button';
    activeNavButton;
    navOffset;
    navHeight = 80;
    navBp = 'md';

    constructor(el){
        super(el);
        if(!el){
            return false;
        }

        this.carousel = new Carousel(el);


        this.nav = this.el.querySelector('[data-hero-nav]');
        this.navContainer = this.el.querySelector('[data-hero-nav-container]');

        this.promo = this.el.querySelector('[data-promo]');

        if(this.promo) {
            this.initPromo();
        }

        if(this.nav && this.navContainer) {
            setTimeout(function() {
                this.navService = new NavService(this.navHeight);

                if(this.navService.items.length) {
                    this.createNavButtons();
                    this.setNavOffset();
                    window.addEventListener('resize', this.setNavOffset.bind(this));
                    window.addEventListener('scroll', this.checkNavOffset.bind(this));
                }
            }.bind(this));
        }

        setTimeout(() => {
            this.loaded();
        })
    }
    
    initPromo() {
        this.promoSpacer = this.el.querySelector('[data-promo-spacer]');
        this.promoHeight = this.promo.offsetHeight;

        let promoTimeout = false;

        window.addEventListener('resize', (e) => {
            if(promoTimeout) {
                clearTimeout(promoTimeout);
            }else{
                this.promoSpacer.classList.add('resize');
            }

            this.promoHeight = this.promo.offsetHeight;

            promoTimeout = setTimeout(() => {
                this.promoSpacer.classList.remove('resize');
                promoTimeout = false;
            }, 200);

            if(this.promo.classList.contains('active')) {
                this.promoSpacer.style.height = `${this.promoHeight}px`;
            }
        });

        const closeButton = this.promo.querySelector('[data-promo-close]');
        closeButton.addEventListener('click', this.closePromo.bind(this));

        setTimeout(() => {
            this.openPromo();
        }, 500)
    }

    closePromo() {
        this.promoSpacer.style.height = 0;
        this.promo.classList.remove('active');
    }

    openPromo() {
        this.promoSpacer.style.height = `${this.promoHeight}px`;
        this.promo.classList.add('active');
    }

    checkNavOffset() {
        if(window.pageYOffset >= this.navOffset) {
            this.navContainer.classList.add('sticky');
        }else{
            this.navContainer.classList.remove('sticky');
        }
    }

    setNavOffset() {
        this.navOffset = getVerticalOffset(this.navContainer);
        this.checkNavOffset();
    }

    createNavButtons() {
        let visibleButton;

        this.navService.items.forEach((item, index) => {
            const button = document.createElement('div');
            button.innerText = item.label;
            button.classList.add(this.buttonClass);

            const icon = document.createElement('i');
            button.appendChild(icon);
            icon.addEventListener('click', (e) => {
                this.navIconHandler(e);
            })

            if(index === 0 || item.offset <= window.pageYOffset + this.navHeight) {
                visibleButton = button;
            }

            button.addEventListener('click', (e) => {
                this.navHandler(e, index, button);
            });

            this.nav.appendChild(button);
        });

        visibleButton.classList.add(this.visibleClass);
        this.activeNavButton = visibleButton;
    }

    navIconHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        if(this.nav.classList.contains(this.activeClass)) {
            this.nav.classList.remove(this.activeClass);
        }else{
            this.nav.classList.add(this.activeClass);
        }
    }

    navHandler(event, index, button) {
        event.preventDefault();
        let pad = 0;

        if( mq(this.navBp) ) {
            //desktop logic
            if(button.classList.contains('active')) {
                return;
            }
        }else{
            //mobile logic (open/close dropdown)
            if(!this.nav.classList.contains(this.activeClass)) {
                this.nav.classList.add(this.activeClass);
                return;
            }else{
                if(this.activeNavButton) {
                    this.activeNavButton.classList.remove(this.visibleClass);
                }

                button.classList.add(this.visibleClass);
                this.nav.classList.remove(this.activeClass);
            }
        }

        // shared logic (change active classes and scroll to target)
        if(this.activeNavButton) {
            this.activeNavButton.classList.remove(this.activeClass);
        }

        this.activeNavButton = button;
        this.activeNavButton.classList.add('active');
        this.navService.scrollToTarget(index);

    }
}