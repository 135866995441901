import DomComponent from '../../lib/domComponent';

export default class PromoBannerComponent extends DomComponent {
    static selector = '.hero__promo-message--standalone[data-promo]';

    constructor(el) {
        super(el);

        if(!this.el) {
            return false;
        }
        
        this.closeButton = this.el.querySelector('[data-promo-close]');
        this.closeButton.addEventListener('click', this.closeHandler.bind(this));
        setTimeout(() => {
            this.loaded();
        })
    }

    closeHandler() {
       this.el.remove();
    }
}