export default class DomComponent {
    el;

    constructor(el) {
        if(el) {
            this.el = el;
            this.el.classList.add('js');
        }
    }

    loaded() {
        this.el.classList.add('js-loaded');
    }

    static getEntryElements() {
        return document.querySelectorAll(this.selector);
    }
}