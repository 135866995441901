import { scrollTo, getVerticalOffset, each } from "../lib/util";
import { durationSlow } from "../lib/const";

export default NavService;

function NavService(navHeight) {
    let items = [];

    console.log(navHeight);
    
    each( document.querySelectorAll('[data-nav-section]'), (navItem, index) => {
        const sectionName = navItem.dataset.navSection;
        const notAllowed = ['', 'data-nav-section'];
        const label = notAllowed.indexOf(sectionName) === -1
            ? sectionName 
            : navItem.textContent.trim();

        items.push({
            label: label,
            index: index,
            offset: getVerticalOffset(navItem),
            el: navItem
        });
    });

    // on resize we set the a offset
    window.addEventListener('resize', (e) => {
        items.forEach((item) => {
            item.offset = getVerticalOffset(item.el);
        });
    })

    return {
        scrollToTarget : function(index, pad) {
            const scrollEl = document.scrollingElement || document.documentElement;
            pad = typeof pad !== 'undefined' ? pad : 0;

            return scrollTo(scrollEl, items[index].offset - navHeight - pad, durationSlow);
        },
        items: items
    }
}