import {mqMap} from './const';

export function mq(breakpoint, direction = 'min-width') {
    if(mqMap.hasOwnProperty(breakpoint)) {
        breakpoint = mqMap[breakpoint] + 'px';
    }

    // if we pass in two breaks, do the inbetwen thingy
    if(mqMap.hasOwnProperty(direction)) {
        breakpoint = [breakpoint];

        breakpoint.push(mqMap[direction] + 'px');
    }
    let query;
    if(typeof breakpoint === 'string') {
        query = `(${direction}: ${breakpoint})`;
    }else{
        query = `(min-width: ${breakpoint[0]}) and (max-width: ${breakpoint[1]})`;
    }

    return window.matchMedia(query).matches;
}

/**
 * Debounces a function to minimize the number of times it gets called over a given time interval.
 * @param fn The function to bounce.
 * @param interval The number of milliseconds to debounce.
 * @param slide {boolean} The "slide" option will cause the function execution to slide further into
 *        the future each time the function is called. Defaults to true. When false, the function
 *        will execute within the timeout regardless of prior calls.
 * @returns {Function} The function that is debounced.
 */
export function debounce(fn, interval, slide) {

    // Set default.
    slide = typeof(slide) === 'undefined'
        ? true
        : slide;

    // Which sort of debounce should be executed?
    if (slide) {
        return debounceWithSlide(fn, interval, this);
    } else {
        return debounceWithoutSlide(fn, interval, this);
    }

}

function debounceWithSlide(fn, interval, context) {
    let timeout;
    return () => {
        clearTimeout(timeout);
        timeout = setTimeout(fn.apply.bind(fn, this, arguments), interval);
    };
}

function debounceWithoutSlide(fn, interval, context) {
    let timeout = null;
    return () => {
        if (timeout) {
            return;
        }
        timeout = setTimeout(function () {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            fn.apply.bind(fn, context, arguments)();
        }, interval);
    };
}

/**
 * Added because the above debounce did not provide correct context
 *
 * @export
 * @param {*} func
 * @param {*} wait
 * @param {*} immediate
 * @returns
 */
export function debounce2(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

/**
 * Creates a custom event, extra steps for IE support
 *
 * @export
 * @param {string} name
 */
export function createEvent(name) {
    const event = document.createEvent('Event');

    event.initEvent(name, true, true);
    return event;
}

export function each(nodeList, cb) {
    if(!nodeList.length) {
        return;
    }
    for (let i = 0; i < nodeList.length; i++) {
        cb(nodeList[i], i);
    }
}

/**
 * Simulate a click event.
 * @public
 * @param {Element} elem  the element to simulate a click on
 */
export function click(elem) {
	// Create our event (with options)
	var evt = new MouseEvent('click', {
		bubbles: true,
		cancelable: true,
		view: window
	});
	// If cancelled, don't dispatch our event
	var canceled = !elem.dispatchEvent(evt);
};

export function getUrlParamByName(parameterName) {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}


export function scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

export function getVerticalOffset(element) {
    return element.getBoundingClientRect().top + window.pageYOffset;
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
      if (t < 1) return c/2*t*t + b;
      t--;
      return -c/2 * (t*(t-2) - 1) + b;
};

// you guessed it, capitalizes a strings first letter
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getIndex(elem){
    var  i= 0;
    while((elem=elem.previousElementSibling)!=null) ++i;
    return i;
}

export function getUrlParams() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

export function querify(obj) {
    return Object.entries(obj).map(([key, val]) => `${key}=${val}`).join('&');
}

export function isDev() {
    return window.location.origin.indexOf('localhost') > -1;
}