const bodyService = {

    lock: function() {
        if(!document.body.classList.contains('lock')) {
            document.body.classList.add('lock');
        }
    },

    unlock: function() {
        document.body.classList.remove('lock');
    },

    islocked: function(){
        return document.body.classList.contains('lock');
    }
}

export default bodyService;