import WidgetBase from '../widget.base';
import {each, isDev, getUrlParams, querify} from '../../lib/util';
import SendRequest from '../../lib/ajax';
import resultTemplate from './search-result.template.pug';
// import * as mockData from './mock-data';


export default class SearchResultsWidget extends WidgetBase {
    static selector = '[data-js-search-results]';
    api = 'umbraco/rhythm/searchapi/search';
    data;
    loader;
    noResults;
    params = {};
    resultList;
     allowedParams = ['term', 'skip', 'take', 'search'];
     loadMore;
    defaultParams = {
        term: "lorem",
        skip: 0,
        take: 5,
    };

    constructor(el){
        super(el); 
        if(!el){
            return false;
        }
   
        this.noResults = this.el.querySelector('[data-js-no-results]');
        this.resultList = this.el.querySelector('[data-js-result-list]');
        this.loader = this.el.querySelector('[data-js-loader]');
        this.loadMore = this.el.querySelector('[data-more]');
        this.loadMore.addEventListener('click', this.loadMoreHandler.bind(this));

        this.getInitialParams();
        this.getContent();

        setTimeout(() => {
            this.loaded();
        })
    }

    getContent(clear) {
        const queryString = querify(this.params);
        let url = this.api + '?' + queryString;
        if(isDev()) {
            url = 'http://dev.anchorum.com/' + url;
        }

        // const res = mockData;
        //             const data = JSON.parse(res.text);
        //             this.data = data;
        //             this.render(data.items, clear);
        return new SendRequest(url)
            .then((res) => {
                if(res && res.hasOwnProperty('text')) {
                    // res = mockData.mock;
                    const data = JSON.parse(res.text);
                    this.data = data;
                    this.render(data.items, clear);
                }
            })
            .catch((err) => {
                if(err) {
                    console.error(err);
                }
            });
    }



    clear() {
        this.grid.innerHTML = '';
    }

    render(items, clear) {
        let html =  this.resultList.innerHTML;
        each(items, (item) => {
            html += resultTemplate(item);
        });
        // items.forEach((item) => {
        //     html += resultTemplate(item);
        // });
        if(items.length == 0){
            this.noResults.classList.remove('hidden');
        } else {
            this.noResults.classList.add('hidden');
        }
        
        this.loader.classList.add('hidden');
        this.resultList.innerHTML = html;

        if(this.resultList.children.length >= this.data.count) {
             this.loadMore.classList.add('hidden');
        }else{
             this.loadMore.classList.remove('hidden');
        }
    }

    loadMoreHandler() {
        this.params.skip = this.params.skip + this.params.take;
        this.getContent();
    }

    getInitialParams() {
        const supplied = getUrlParams();
        let initial = {};

        this.allowedParams.forEach((param) => {
            if(supplied.hasOwnProperty(param)) {
                initial[param] = supplied[param];
            }
        });
        this.params = Object.assign(this.defaultParams, initial);
    }
}