import {each} from '../lib/util';

export default function ImageCaptionService(rootElm) {
    const images = rootElm.querySelectorAll('img');
    
    each(images, (image) =>{
        if(image.alt.length > 0){
            const paragraph = document.createElement('p');
            paragraph.style.fontStyle = "italic";
            paragraph.className = "image-caption-text";
            paragraph.innerHTML = image.alt;
            image.parentNode.insertBefore(paragraph, image.nextSibling);
        }
    });
}
