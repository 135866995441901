import Hammer, { DIRECTION_HORIZONTAL } from 'hammerjs';
import { each } from '../lib/util';
import DomComponent from '../lib/domComponent';

export default class Carousel {
    // The current index number
    index = 0;

    // Auto play delay in milleseconds;
    autoPlayDelay = 5000;

    // Auto play interval ID
    autoPlayIntervalId = '';

    // The total number of slides
    numOfSlides = 0;

    // Are clones used
    areClonesUsed = false;

    // List of dots
    dots = [];

    constructor(el){
        this.el = el;
        this.init();
    }

    /**
     * Initialize the Carousel
     */
    init() {
        // Clone slides if needed
        this.cloneSlides();
        // Cache the DOM
        this.cacheDOM();
        // Event listeners
        this.eventListeners();
        // Get the number of slides
        this.numOfSlides = this.slides.length;
        // Display the carousel
        this.displayCarousel();
        // Display the controls
        this.displayControls();
        // Add touch controls
        this.touchControls();
        // Start auto play 
        this.startAutoPlay();
    }

    /**
     * Close slides when there are only 2 slides in the carousel
     */
    cloneSlides() {
        // The slides container
        this.slidesContainer = this.el.querySelector('[data-slides-container]');

        // Check to see if there are only 2 slides in the carousel
        if (this.slidesContainer.children.length === 2) {
            // Clone the first slide
            const slide1Clone = this.slidesContainer.children[0].cloneNode(true);
            // Clone the second slide
            const slide2Clone = this.slidesContainer.children[1].cloneNode(true);
            // Append the first cloned slide
            this.slidesContainer.appendChild(slide1Clone);
            // Append the second cloned slide
            this.slidesContainer.appendChild(slide2Clone);
            // Set the areClonesUsed flag to true
            this.areClonesUsed = true;
        }
    }

    /**
     * Cache the DOM elements
     */
    cacheDOM() {
        // The list of slide
        this.slides = this.el.querySelectorAll('[data-slide]');
        // The controls container
        this.controls = this.el.querySelector('[data-controls]');
        // The previous button
        this.prevButton = this.el.querySelector('[data-prev-btn]');
        // The next button
        this.nextButton = this.el.querySelector('[data-next-btn]');
        // The dots container
        this.dotsContainer = this.el.querySelector('[data-dots]');
    }

    /**
     * Event listeners
     */
    eventListeners() {
        // Previous button event listener
        this.prevButton.addEventListener('click', this.prevSlide.bind(this));
        // Next button event listener
        this.nextButton.addEventListener('click', this.nextSlide.bind(this));

        // Auto play event listeners
        this.el.addEventListener("mouseenter", this.stopAutoPlay.bind(this));        
        this.el.addEventListener("mouseleave", this.startAutoPlay.bind(this));
    }

    /**
     * Display the carousel or just a single slide
     */
    displayCarousel() {
        // Check to see if there is only 1 slide
        if (this.numOfSlides === 1) {
            // Display the only slide
            this.slides[0].classList.add('current');
        }
        // Use the regular carousel if there are at least 3 slides
        else if (this.numOfSlides > 2) {
            // Display the dots
            this.displayDots();
            // Display the first slide
            this.goToSlide(0);
        }
    }

    /**
     * Display the Controls. ie. The previous and next buttons and the slide counter
     */
    displayControls() {
        // Check to see if the are more than 1 side
        if (this.numOfSlides === 1) {
            // If only 1 slide, then remove the controls
            this.controls.style.display = 'none';
        }
    }

    /**
     * Display the Dots
     */
    displayDots() {
        const numOfSlides = this.areClonesUsed ? 2 : this.numOfSlides;

        for (let i = 0; i < numOfSlides; i++) {
            const btn = document.createElement('BUTTON');
            btn.setAttribute('type', 'button');
            btn.setAttribute('aria-label', `go to slide number ${i + 1}`)
            if (i === 0) {
                btn.classList.add('active');
            }
            btn.addEventListener('click', () => {
                this.goToSlide(i);
            });
            this.dotsContainer.appendChild(btn);
            this.dots.push(btn);
        }
    }

    /**
     * Add touch controls to the carousel
     */
    touchControls() {
        const mc = new Hammer.Manager(this.slidesContainer);
        const swipe = new Hammer.Swipe({ direction: DIRECTION_HORIZONTAL });
        mc.add(swipe);
        mc.sensitivity = 25;
        // Show the Next Slide
        mc.on('swipeleft', () => {
            this.stopAutoPlay();
            this.nextSlide();
            this.startAutoPlay();
        });
        // Show the Previous Slide
        mc.on('swiperight', () => {
            this.stopAutoPlay();
            this.prevSlide();
            this.startAutoPlay();
        });
    }

    /**
     * Remove all the classes from the slides
     */
    removeAllClasses() {
        // Loop though all the slides
        each(this.slides, (slide) => {
            // Remove the current class
            slide.classList.remove('current');
            // Remove the next class
            slide.classList.remove('next');
            // Remove the prev class
            slide.classList.remove('prev');
            // Remove the in-transition class
            slide.classList.remove('in-transition');
        });
    }

    /**
     * Update the dots
     */
    updateDots() {
        this.dots.forEach(dot => {
            dot.classList.remove('active');
        });
        if (this.areClonesUsed) {
            this.dots[this.index % 2].classList.add('active');
        } else {
            this.dots[this.index].classList.add('active');
        }
    }

    /**
     * Go to the given index
     *
     * @param {int} newCurrent The new index of the carousel
     */
    goToSlide(newCurrent) {
        // Update the new next index
        const newPrev = (newCurrent - 1 < 0) ? this.numOfSlides - 1 : newCurrent - 1;
        // Update the new previous index
        const newNext = (newCurrent + 1 === this.numOfSlides) ? 0 : newCurrent + 1;
        // Remove all the classes added to the slides
        this.removeAllClasses();
        // Add the current class to the new current slide
        this.slides[newCurrent].classList.add('current');
        // Add the next class to the new next slide
        this.slides[newNext].classList.add('next');
        // Add the prev class to the new previous slide
        this.slides[newPrev].classList.add('prev');
        // Add the in-transition class to the current slide so it can transition out of view
        this.slides[this.index].classList.add('in-transition');
        // Update the current index
        this.index = newCurrent;
        // Update dots
        this.updateDots();
    }

    /**
     * Go to the previous slide
     */
    prevSlide() {
        // Get the previous slide index
        const prevSlide = (this.index - 1 < 0) ? this.numOfSlides - 1 : this.index - 1;
        // Go to the previous slide
        this.goToSlide(prevSlide);
    }

    /**
     * Go to the next slide
     */
    nextSlide() {
        // Get the next slide index
        const nextSlide = (this.index + 1 === this.numOfSlides) ? 0 : this.index + 1;
        // Go to the next slide
        this.goToSlide(nextSlide);
    }

    /**
     * Start auto play to next slide.
     */
    startAutoPlay() {
        // Clear any existing auto play intervals
        this.stopAutoPlay();

        this.autoPlayIntervalId = setInterval(() => this.nextSlide(), this.autoPlayDelay);
    }
    
    /**
     * Stop auto play to next slide.
     */
    stopAutoPlay() {
        if (this.autoPlayIntervalId)
        {
            clearInterval(this.autoPlayIntervalId);
        }
    }
}