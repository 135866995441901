// Dependencies.
import SendRequest from "../../lib/ajax";

// Ensure validators have been initialized.
let key = "formulate-plain-js-validators";
window[key] = window[key] || [];

/**
 * Validates a field to ensure its value is a valid email address.
 * @constructor
 */
function BriteVerifyValidator() {
}

/**
 * Validates the the specified text is a valid email address.
 * @param value {string} The text value to validate.
 * @returns {Promise} A promise that resolves to true, if the text is valid; otherwise, false.
 */
BriteVerifyValidator.prototype.validateText = function (value) {
    return new Promise(function (resolve) {
        let encodedEmail = encodeURIComponent(value),
            url = "/umbraco/rhythm/briteverifyapi/verify?email=" + encodedEmail;
        new SendRequest(url)
            .then(function (result) {
                let success = result.text === "true";
                resolve(success);
            });
    });
};

// Does nothing. Only here to satisfy the contract.
BriteVerifyValidator.prototype.validateTextArray = function (value) {
    return new Promise(function (resolve) {

        // Fake it, as we don't care about validating text arrays.
        resolve(true);

    });
};

// Does nothing. Only here to satisfy the contract.
BriteVerifyValidator.prototype.validateFile = function (value) {
    return new Promise(function (resolve) {

        // Fake it, as we don't care about validating files.
        resolve(true);

    });
};

// Store the field validator configuration so Formulate will pick it up.
window[key].push({
    key: "ValidationBriteVerify",
    validator: BriteVerifyValidator
});