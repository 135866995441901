
import {each} from '../../lib/dom';
import WidgetBase from '../widget.base';

export default class CareersAccordion extends WidgetBase {
    static selector = '[data-js-careers-acc]';
    previousActiveContent;
    previousActiveIcon;
    previousActiveHeader;
    el;

    constructor(el) {
        if(!el) {
            return false
        }

        

        super(el);

        this.toggles = this.el.querySelectorAll('[data-accordion-toggle]');

        each(this.toggles, (toggle) => {
            const header = toggle.firstElementChild;
            const content = header.nextElementSibling;
            const icon = toggle.querySelector('[data-accordion-icon]');
                
            toggle.addEventListener('click', () => {
                if(header.classList.contains('active')) {
                    header.classList.remove('active');
                    content.classList.remove('active');
                    icon.classList.remove('active');
                }else{
                    if(this.previousActiveContent){
                        this.previousActiveContent.classList.remove('active');
                    }
                    if(this.previousActiveIcon){
                        this.previousActiveIcon.classList.remove('active');
                    }
                    if(this.previousActiveHeader){
                        this.previousActiveHeader.classList.remove('active');
                    }
                    header.classList.add('active');
                    content.classList.add('active');
                    icon.classList.add('active');

                    this.previousActiveContent = content;
                    this.previousActiveHeader = header;
                    this.previousActiveIcon = icon;
                }
            })
            
        });

        setTimeout(() => {
            this.loaded();
        })

    }
} 