import bodyService from '../services/body';
import DomComponent from '../lib/domComponent';

export default class HeaderComponent extends DomComponent {
    static selector = '[data-js-header]';
    el;
    
    searchActive = false;
    activeClass = 'active';
    
    submenuNameSelector = '[data-name-placeholder]';
    submenuImageSelector = '[data-submenu-image]';
    submenuNavSelector = '[data-submenu-nav]';
    submenuNavItemSelector = '[data-submenu-nav-item]';
    submenuDefaults = {};
    

    constructor(el) {
        // return false
        super(el);
        this.mobileMenuToggle = this.el.querySelector('[data-mobile-menu-toggle]');
        this.mobileMenu = this.el.querySelector('[data-mobile-menu]');
        this.search = this.el.querySelector('[data-search]');
        this.searchInput = this.el.querySelector('[data-search-input]');
        this.searchTrigger = this.el.querySelector('[data-search-trigger]');
        this.searchCloseTrigger = this.el.querySelector('[data-close-search]');
        this.searchForm = this.el.querySelector('form');
        // attach events

        this.searchForm.onsubmit = this.hijackFormSubmit.bind(this);
        this.searchTrigger.addEventListener('click', this.searchHandler.bind(this));
        this.searchCloseTrigger.addEventListener('click', this.closeSearch.bind(this));
        this.mobileMenuToggle.addEventListener('click', this.toggleMobileMenu.bind(this));

        this.searchInput.addEventListener('keyup', this.checkForEnter.bind(this));

        setTimeout(() => {
            this.loaded();
        });
    }

    hijackFormSubmit(e){
        e.preventDefault();
        this.searchActive = true;
        const input = this.searchForm.querySelector('input').value;
        this.searchInput.value = input;
        this.searchHandler();
        return false;
    }

    checkForEnter(e){
        if(e.keyCode === 13){
            this.searchHandler();
        }
    }

    searchHandler() {
        if(this.searchActive) {
            this.performSearch();
        }else{
            this.searchActive = true;
            this.search.classList.add(this.activeClass);
            this.searchInput.focus();
        }
    }

    performSearch(){
        if(this.searchInput.value.length){
            const search = this.searchInput.value;
            const params = "&take=5&skip=0";
            const queryString = `?term=${encodeURIComponent(search)}${params}`
            window.location = "/search"+queryString;
        }
    }


    closeSearch() {
        this.searchActive = false;
        this.search.classList.remove(this.activeClass);
    }

    toggleMobileMenu() {
        if(!this.mobileMenuToggle.classList.contains('active')) {
            // open menu
            this.mobileMenuToggle.classList.add('active');
            this.mobileMenu.classList.add('active');
            bodyService.lock();

        }else{
            // close menu
            this.mobileMenuToggle.classList.remove('active');
            this.mobileMenu.classList.remove('active');
            bodyService.unlock();
        }
    }
} 