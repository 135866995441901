export const mqMap = {
    xs: 480,
    sm: 768,
    md: 980,
    lg: 1280,
    xl: 1690,
    navDesktop: 1690,
};

export const widgetSpacing = {
    default: 40,
    md: 60,
}

export const duration = 300;
export const durationSlow = 600;
export const navDesktopMQ = 'navDesktop';
export const mobileNavHeight = 60;