// import 'Styles/themes/_wiski-critical.scss';

// TODO remove for prod
import 'Styles/styles.scss';

// This import will add Promise to the window.
import "promiscuous/promiscuous-browser-full";

// Formulate customizations.
import "./components/formulate/brite-verify-validation";

// Formulate plain JavaScript frontend.
import "../responsive.plain-javascript/plain-index";

import "./lib/polyfills";

import { each } from './lib/util';
import HeaderComponent from './header/header';
import ModalComponent from './components/modal/modal';
import PromoBannerComponent from './components/promo-banner/promo-banner';
import BioModalComponent from './components/modal/bio-modal';
import HeroWidget from './widgets/hero/hero.widget';
import HeroCarouselWidget from './widgets/hero-carousel/hero-carousel.widget';
import CareersAccordion from './widgets/careers-accordion/careers-accordion.widget';
import BlogGalleryWidget from './widgets/blog/blog-gallery.widget';
import SearchResultsWidget from './widgets/search-results/search-results.widget';
import ValidationComponent from './forms/validation';
import DualPGCL from './widgets/dual-pane-graphical-callout-list/dual-pane-graphical-callout-list.widget';
import RichText from './widgets/rich-text/rich-text.widget';
// import FormsComponent from './forms/forms';
// import './services/animations';

// list of components that will fire conditionally depending on whether
// the static selector property on them matches any dom elements
const components = [
    HeaderComponent,
    HeroWidget,
    HeroCarouselWidget,
    BlogGalleryWidget,
    SearchResultsWidget,
    ModalComponent,
    PromoBannerComponent,
    BioModalComponent,
    CareersAccordion,
    DualPGCL, 
    RichText
];

if(typeof DEVELOPMENT !== 'undefined' && DEVELOPMENT) {
    window.cssLoaded = true;
}

//bootstrap
if (window.cssLoaded) {
    init();
} else {
    window.appInit = function(){
        setTimeout(() => {
            init();
        }, 0);
    };
}

function init() {
    let apiMap = {};
    var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
    if (touchsupport){ // browser doesn't support touch
        document.documentElement.classList.add('js-touch');
    }


        components.forEach((cmp) => {
            const api = initComponent(cmp);
    
            if(api) {
                apiMap[api[0]] = api[1];
            }
        });
  
}

function initComponent(cmp) {
    let cmpArr = [];
    let cmpName;
    let cmpsOnPage = cmp.getEntryElements();
    if(cmpsOnPage.length) {
        each(cmpsOnPage, (el) => {
            const cmpInstance = new cmp(el);
            cmpName = cmpInstance.constructor.name;
            cmpArr.push(cmpInstance);
        });
        return [cmpName, cmpArr];
    }else{
        return false;
    }
}
