import WidgetBase from '../widget.base';
import ImageCaptionService from '../../services/image-caption';


export default class DualPaneWidget extends WidgetBase {
    static selector = '[data-js-dual-pgcl]';

    
    constructor(el){
        super(el); 
        if(!el){
            return false;
        }

        this.imgCaptionService = new ImageCaptionService(el);

   

        setTimeout(() => {
            this.loaded();
        })
    }
}