import DomComponent from '../../lib/domComponent';
import body from '../../services/body';
import { each } from '../../lib/util';
import { duration } from '../../lib/const';

export default class ModalComponent extends DomComponent {
    static selector = '[data-js-bio-modal]';

    constructor(el) {
        super(el);

        if(!this.el) {
            return false;
        }

        
        this.closeButton = this.el.querySelector('[data-close]');
        this.modalTriggers = this.el.querySelectorAll('[data-bio-modal-target]');
        this.modalContents = this.el.querySelectorAll('[data-bio-modal-content]');
        this.videoModalContent = this.el.querySelector('[data-bio-modal-content="video-modal"]');
        this.modalBox = this.el.querySelector('[data-bio-modal-box]');
        this.videoModalTriggers = this.el.querySelectorAll('[data-bio-video-id]');
        this.video = this.el.querySelector('[data-bio-modal-video]');
        this.modalBg = this.el.querySelector('.modal__bg');
        this.popup = this.el.querySelector('[data-js-bio-modal-popup]');
        this.closeButton.addEventListener('click', this.closeModalHandler.bind(this));
        
       
        each(this.videoModalTriggers, (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();
                this.prepareVideo(trigger.dataset.videoId);
                this.openModal(this.videoModalContent);
            })
        })

        // triggers that open modal
        each(this.modalTriggers, (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                const target = trigger.dataset.bioModalTarget;
                each(this.modalContents, (content) => {
                    if(target === content.dataset.bioModalContent) {
                        this.openModal(content);
                    }
                });
            })
        });

        if(this.modalBg){
            this.modalBg.addEventListener('click', (e) => {
                this.closeModalHandler();
            });
        }

        setTimeout(() => {
            this.loaded();
        })
    }

    closeModalHandler() {
        this.popup.classList.remove('open');
        body.unlock();
        
        setTimeout(() => {
            each(this.modalContents, (content) => {content.style.display = 'none'});
            // this.video.removeAttribute('src');
            // this.el.classList.remove('modal--video');
        }, duration);

        if(this.video){
            this.video.setAttribute('src', '');
        }
    }


    


    prepareVideo(videoId) {
        this.video.setAttribute('src', `https://www.youtube.com/embed/${videoId}`);
        this.popup.classList.add('modal--video');
    }
    
    openModal(node) {
        if(body.islocked()){
            return;
        }
        node.style.display = '';
        this.popup.classList.add('open');
        body.lock();
    }
}